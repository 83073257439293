import React from "react"

const LandingPage = () => (
    <div className="container">
        <div className="vertical-center">
            <h1 className="header">nineprimes</h1>
            <p className="lead mt-4">We don't really have a website.</p>
            <p className="lead mt-4">contact @ this domain to email.</p>
        </div>
    </div>
)

export default LandingPage